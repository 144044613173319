import React, { useState, useRef } from "react";
import { graphql } from "gatsby";
import BlockContent from "@sanity/block-content-to-react";
import Section from "../components/Section";
import styled from "styled-components";

import SEO from "../components/SEO";
import { StyledProjectContainer } from "../styles/Containers";

export default function Military({ data, location }) {
  const block = data.military.nodes[1];

  return (
    <>
      <SEO
        title={block?.title}
        location={location}
        description={block?._rawBody[0].children[0].text}
      />
      <Section fullScreen width="700px">
        <StyledProjectContainer width="750px">
          <h1>{block?.title}</h1>
          <div className="header-underline"></div>
          <BlockContent
            blocks={block?._rawBody}
            projectId="hq5iqyv5"
            dataset="production"
          />
        </StyledProjectContainer>
      </Section>
    </>
  );
}

export const militaryQuery = graphql`
  query MyQuery {
    military: allSanityMilitary {
      nodes {
        title
        _rawBody
      }
    }
  }
`;
